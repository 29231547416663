module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-cloudflare-web-analytics-virtual-3e80bdbd16/0/cache/gatsby-plugin-cloudflare-web-analytics-npm-0.3.1-aa59af2b44-2a6b942782.zip/node_modules/gatsby-plugin-cloudflare-web-analytics/gatsby-browser.js'),
      options: {"plugins":[],"token":"250e66ce97fe47888a83ffc7595ba437"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/unplugged/gatsby-virtual-7afc685dd2/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
