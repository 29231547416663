import React, { createContext, useContext } from "react";

const StreamEventNameContext = createContext<string | null>(null);

export const StreamEventNameProvider: React.FC<{
  streamEventName: string | null;
  children: React.ReactNode;
}> = ({ streamEventName, children }) => (
  <StreamEventNameContext.Provider value={streamEventName}>
    {children}
  </StreamEventNameContext.Provider>
);

export function useStreamEventName() {
  return useContext(StreamEventNameContext);
}
