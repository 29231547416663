import "normalize.css";
import "@seed-design/stylesheet/global.css";
import "./src/styles/index.css";

import { WrapPageElementBrowserArgs } from "gatsby";
import React from "react";

import { StreamEventNameProvider } from "./src/contexts";

const searchParams = new URLSearchParams(window.location.search);
const streamEventName = searchParams.get("e");

export const wrapPageElement = ({ element }: WrapPageElementBrowserArgs) => (
  <React.StrictMode>
    <StreamEventNameProvider streamEventName={streamEventName}>
      {element}
    </StreamEventNameProvider>
  </React.StrictMode>
);
